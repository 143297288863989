import React from 'react';
import { Link } from 'react-router-dom';


function Menu(){
    const handleClick = () => {
        window.scrollTo(200, 0);
      } 
    return(
        <div className="main-menu d-none d-lg-block ">
                    <nav>
                        <ul>
                            <li className="has-megamenu">
                                <Link to={'/'} onClick={handleClick}>Home</Link>
                            </li>
                            <li>
                                <Link to="/about" onClick={handleClick}>About</Link></li>
                            <li>
                                < Link to={'/what-we-do'} onClick={handleClick}>What we do</Link>
                            </li>
                           
                            <li>
                                <Link to={'/what-you-can-do'} onClick={handleClick}>What You Can Do</Link>
                            </li>
                            <li>
                                <Link to={''}>Resources Center</Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to={'/gallery'} onClick={handleClick}>Gallery</Link>
                                    </li>
                                    <li>
                                        <Link to={'/stories-of-hope'} onClick={handleClick}>Stories of hope</Link>
                                    </li>                                
                                </ul>
                            </li>
                            <li>
                                <Link to={'/contact'} onClick={handleClick}>Contact</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
    );

}
export default Menu;