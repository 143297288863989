import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const TestimonialSection = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const testimonials = [
    {
      name: "Prakhar ",
      job: " Volunteer",
      text: '"“ It is said, the world is a theater and each one of has a role to play. It is our duty to identify and play our role as best as possible. I envisaged to play a role of teacher, to facilitate street children and know their role. In my journey of volunteering, I have matured intellectually and mentally, as a person.'
    },
    {
      name: " Ms Asha Bazaz",
      job: " Thalassemia Society of India ",
      text: "'It was a pleasure meeting you and your team. I must congratulate you for the success of the event. Although it was an awareness program but so many donors came forward to donate blood. We are happy to have you in our family to work for this humanitarian cause.”"
    },
    {
      name: " Samantha Pasowicz ",
      job: " Intern, USA",
      text: "As an intern with Joining Hands, I feel inspired by the work that is being done at the training center in Badarpur, Delhi. Joining Hands is addressing an urgent need in the community, and in India as a whole, to educate and empower youth so that they can choose their own futures."
    },
    {
      name: "Parul",
      job: "Copal",
      text: "Parul has thanked JH for the support and has got great feedback from the volunteers regarding the event. We have requested feedback from the volunteers so that we can use it on our website. JH has also requested Copal to tie up for their quarterly employee engagement events as a part of the Community Outreach programs with JH partner NGOs."
    },
    {
      name: " Shuchita Garg",
      job: "ISTS Infotech Solutions",
      text: "Joining Hands played a very vital role in organizing the CSR event. They were very supportive and it also appeared that they had prior experience working with software companies. Just keep up the good work by bridging members of the societies who can make donations and the ones who need."
    },
   
    ///new testiminoals
    {
      name: "Anish",
      job: "HR Manager, Reliable Private Limited",
     text: "The candidates sent by Joining Hands are good; they are sincere, have good communication skills, and possess computer knowledge. We are associated with Joining Hands since 2018.",
      },
      {
        name: "Karan",
        job: "Senior Manager, Breakthrough NGO",
        text: "Having coordinated with the team for over two years to hire students for Breakthrough Trust, I can confidently say that the experience has been exceptionally positive. The candidates placed have shown remarkable improvement within a short span, demonstrating impressive confidence.",
      },
      {
        text: "The students placed in our company have consistently exhibited a remarkable commitment to honesty and integrity throughout their tenures. Not only have they mastered corporate-level tasks, but they have also significantly refined their communication skills.",
        name: "Col. Sunit Vijh",
        job: "CEO, Corporate Investigations India"
      },
      {
        text: "As an intern with Joining Hands, I feel inspired by the work that is being done at the training center in Badarpur, Delhi. I am really impressed with the multi-faceted approach to youth development that Joining Hands employs.",
        name: "Samantha Pasowicz",
        job: "Intern, USA"
      },
      {
        text: "We have had a great experience working with Joining Hands. We look forward to getting support from Joining Hands in providing organizations like ours with skilled employees.",
        name: "Naushad Khan",
        job: "Executive Director, Smile Foundation"
      },
      {
        text: "The Cadence scholarship has lowered down the burden from my parent’s shoulders, hence I could sustain my education ahead.",
        name: "Jija Shinde",
        job: "Pursuing MSc (Economics), Pune Scholar"
      },
      {
        text: "I am thankful to Joining Hands for giving me the opportunity to study in a good college.",
        name: "Niveditha",
        job: "Bangalore Scholar, Lecturer"
      },
      {
        text: "This scholarship program has directed me towards my goals and has given me a new life.",
        name: "Laxmi Haldar",
        job: "Working with Hyatt Andaz, Aerocity"
      },
      {
        text: "I am truly grateful for the opportunity you have provided me. Thank you for all who supported this scholarship.",
        name: "Manikesh S",
        job: "Working at Tata Company"
      },
      {
        text: "This scholarship has provided me with peace of mind where I don't need to worry about my fees, allowing me to solely focus on my studies.",
        name: "Ajit",
        job: "Working with Marine Power International FZC (Sharjah, UAE)"
      },
      {
        text: "All my financial burden has been taken over by Cadence and Joining Hands by supporting my daughter’s education.",
        name: "Father of Ruchika Mavi",
        job: "Pursuing MSc (Mathematics)"
      },
      {
        text: "The scholarship has not only supported us financially but gave guidance and direction to my son, who is now performing exceptionally well.",
        name: "Father of Abhishek Ranjan",
        job: "Pursuing BCA, Delhi Scholar"
      },
      {
        text: "I got rejected in many interviews but the connect with my teachers helped me not to lose my confidence. Finally, I got my dream job of data entry.",
        name: "Kavya",
        "job_profile": "Batch -2"
      },
      {
        text: "I used to just lurk around here and there. Joining Hands not only provided technical skills but also changed my outlook towards work. Now I perform all my duties with full dedication and honesty.",
        name: "Ashok",
        job: "Batch -4"
      },
      {
        text: "After enrolling myself in the Joining Hands training centre my English vocabulary has improved considerably. The financial literacy classes also helped to understand the importance of savings and various ways of doing it.",
        name: "Nidhi",
        job: "Working with Reliable, Noida"
      },
      {
        text: "After joining the course my knowledge on computers improved. Earlier, I used to hesitate to speak in English in front of people, but Joining Hands trained and helped me to develop my spoken English skills. The classes on financial literacy also proved to be very helpful for the profile that I am currently working in.",
        name: "Deepak Sahu",
        job: "Team Leader, India Lends"
      },
      {
        text: "I am really glad to get an opportunity to learn at Joining Hands and to get placement at Breakthrough Foundation. I will definitely suggest other youth in my community to join the training center to improve themselves.",
        name: "Geeta",
        job: "Batch -66"
      },
      {
        text: "I recently completed my 3-month Basic IT, English, and soft skill from JOINING HANDS and I was impressed with the level of knowledge and expertise demonstrated by the instructors. The course content was well-structured and covered a wide range of topics, from basic computer skills to soft skills.",
        name: "Anshul Shakya",
        job: "Batch -69"
      },
      {
        text: "I think I cannot match the speed of my classmates, but the faculty of joining hands was very cooperative and they helped me a lot. I was able to secure a job at KFC, Lajpat Nagar due to training only as I was possessing limited educational qualification i.e. 10th class.",
        name: "Sahil",
        job: "Batch -76"
      }

   

  ];

  return (
    <section className="testimonial-area pt-120 pb-120 pattern-dots-sm  ">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-4 mb-60">
          <div className="section-header">
            <h5 className="wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
            <i class="fa-regular fa-angles-left pe-1"></i>Testimonials<i
                                class="fa-regular fa-angles-right ps-1"></i>
            </h5>
            <h2 className="wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">What They Say About Us </h2>
          </div>
          <div className="arry-btn wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
          {/* <button  className="arry-prev testimonial__arry-prev">
          <i
                                class="fa-regular fa-chevron-left"></i>
            </button>
            <button  className="arry-next testimonial__arry-next active ml-10">
            <i
                                class="fa-regular fa-chevron-right"></i> 
            </button> */}
          </div>
        </div>
      </div>
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={30}
        slidesPerView={1.2}
        centeredSlides={true}
        loop={true}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init(); 
          swiper.navigation.update();
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          640: { slidesPerView: 1.6 },
          768: { slidesPerView: 1.2 },
          1024: { slidesPerView: 1.6 },
          // 1280: { slidesPerView: 3.5 }
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="testimonial__item border border-2  " style={{minHeight: "380px"}}>
              <svg className="shape" width="59" height="67" viewBox="0 0 59 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="59" height="67">
                  <rect width="59" height="67" fill="#F74F22"/>
                </mask>
                <g mask="url(#mask0)">
                  <circle opacity="0.7" cx="59" cy="9" r="56" fill="#F74F22"/>
                  <circle cx="59" cy="9" r="47.5" fill="#F74F22" stroke="white" strokeDasharray="2 2"/>
                </g>
              </svg>
              <div className="top">
                
                <div className="con section-header">
                  <div className="star">
                  <i class="fa-sharp fa-solid fa-star"></i>
                                        <i class="fa-sharp fa-solid fa-star"></i>
                                        <i class="fa-sharp fa-solid fa-star"></i>
                                        <i class="fa-sharp fa-solid fa-star"></i>
                                        <i class="fa-sharp fa-solid fa-star"></i>
                  </div>
                  <h5 style={{paddingBottom: 0}}>{testimonial.name}</h5>
                  <span>{testimonial.job}</span>
                </div>
              </div>
              <p className="mt-30">{testimonial.text}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default TestimonialSection;