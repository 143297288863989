import React from 'react'

export const Underline = () => {
  return (
    <div className=' d-flex justify-content-center align-items-center mt-2' style={{gap:"10px"}}>
                        <span className='border border-2 justify-center' style={{width:'70px',height:"3px"}}></span>
                        <span className='border border-2  rounded-5'  style={{width:'20px', height:"20px",backgroundColor:"#f74f22"}}></span>
                        <span className='border border-2' style={{width:'70px',height:"3px"}}></span>
                      </div>
  )
}

