import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import './BrandSlider.css';


const BrandSlider = () => {
  const brands = [
    { id: 1, image: "images/brand/cadence.png" },
    { id: 2, image: "images/brand/axa.png" },
    { id: 3, image: "images/brand/boston.png" },
    { id: 4, image: "images/brand/concern.png" },
    { id: 5, image: "images/brand/macquarie.png" },
    { id: 6, image: "images/brand/nxp.png" },
    { id: 7, image: "images/brand/picture4.png" },
    { id: 8, image: "images/brand/ustech.jpg" },
    { id: 9, image: "images/brand/meridien.jpg" },
    { id: 10, image: "images/brand/oxigen.jpg" },
    { id: 11, image: "images/brand/tripoto.jpg" },
    { id: 12, image: "images/brand/truly-modly.jpg" },
    { id: 13, image: "images/brand/volvoline.jpg" },
    // { id: 1, image: "images/brand/meridien.jpg" },
    // { id: 2, image: "images/brand/oxigen.jpg" },
    // { id: 3, image: "images/brand/tripoto.jpg" },
    // { id: 4, image: "images/brand/truly-modly.jpg" },
    // { id: 5, image: "images/brand/volvoline.jpg" },


    // ------------
   
   
  ];

  return (
    <div className="brand-area pt-100 pb-100 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="1500ms">
      <div className="container">
        <Swiper
          modules={[Autoplay]}
          className="brand__slider"
          spaceBetween={30}
          slidesPerView={2}
          loop={true}
          autoplay={{
            delay: 2000000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            640: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
        >
          {brands.map((brand) => (
            <SwiperSlide key={brand.id}>
              <div className="image  flex  items-center ">
                <img src={brand.image} className='' alt={`Brand ${brand.id}`} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BrandSlider;