import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import WOW from 'wowjs';

 const Project = () => {


    useEffect(() => {
        // Initialize WOW.js
        new WOW.WOW({
          live: false
        }).init();
      }, []);
    
      // Store brand images in an array
        const projects = [
          { image: "/images/project/event-3.jpg", delay: "00ms", col: "col-md-6",  },
          { image: "/images/project/event-4.jpg", delay: "200ms", col: "col-md-6",},
          { image: "/images/project/event-5.jpg", delay: "00ms", col: "col-md-7",  },
          { image: "/images/project/higher-education-2.jpg", delay: "200ms", col: "col-md-5",  },
          { image: "/images/project/higher-education-3.jpg", delay: "00ms", col: "col-md-6",  },
          { image: "/images/project/higher-education.jpg", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/hospitalaity-1.jpg", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/hospitalaity-2.jpg", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/hospitalaity-3.jpg", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/other-3.jpg", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/other-4.jpg", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/other-5.jpg", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/other-6.jpg", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/other-7.jpg", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/other-8.jpg", delay: "200ms", col: "col-md-6",name:"lex" },
          { image: "/images/project/other-9.jpg", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/other-14.jpg", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/training26.jpg", delay: "200ms", col: "col-md-6", },
          // group image start 
          { image: "/images/project/group/group_new_scholar.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group_interaction_Pune_scholars.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group_interaction_with_scholars.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group_bangalore.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group_1.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group-pic.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group_pic_Delhi.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group_pic2.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group_pic_4.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group_pic_Bangalore.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group_pic_delhi_2.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group_pic_pune2.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group_pic_pune.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group_session.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/group_pic-2_Delhi.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/round/round-1.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/round/round-2.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/round/round-3.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/round/dsc_1.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/round/dsc_2.webp", delay: "200ms", col: "col-md-6", },
          //alumni
          { image: "/images/project/alumni/alumni-1.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/alumni/alumni-2.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/alumni/art-1.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/alumni/art-2.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/alumni/briefing_inteview.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/alumni/candence_scholarship.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/alumni/career-1.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/alumni/career-2.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/alumni/career-3.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/alumni/img-1.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/alumni/img-2.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/alumni/img-3.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/home_visit.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/home_visit_2.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/group/scholarship_letter.webp", delay: "200ms", col: "col-md-6", },
          //session
          { image: "/images/project/sessions/session-1.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-2.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-3.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-4.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-5.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-6.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-7.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-8.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-9.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-10.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-11.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-12.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-13.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-14.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/sessions/session-15.webp", delay: "200ms", col: "col-md-6", },
          //nxp
          { image: "/images/project/nxp/nxp-1.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/nxp/nxp-2.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/nxp/nxp-3.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/nxp/nxp-4.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/nxp/nxp-5.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/nxp/nxp-6.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/nxp/nxp-7.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/nxp/nxp-8.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/nxp/nxp-9.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/nxp/nxp-10.webp", delay: "200ms", col: "col-md-6", },
          //skills development

          { image: "/images/project/skills-development/skills-2.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/skills-development/skills-3.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/skills-development/skills-4.webp", delay: "200ms", col: "col-md-6", },
          // { image: "/images/project/skills-development/skills-5.webp", delay: "200ms", col: "col-md-6", },
          // { image: "/images/project/skills-development/skills-6.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/skills-development/skills-7.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/skills-development/skills-8.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/skills-development/skills-9.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/skills-development/skills-10.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/skills-development/skills-11.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/skills-development/skills-12.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/skills-development/skills-13.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/skills-development/skills-14.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/skills-development/skills-15.webp", delay: "200ms", col: "col-md-6", },
          { image: "/images/project/skills-development/skills-1.webp", delay: "200ms", col: "col-md-6", },





        ];
      
        const ProjectItem = ({ image, delay, col,name }) => (
            <div className="col-md-4   wow fadeInDown" data-wow-delay={delay} data-wow-duration="1500ms" style={{maxHeight:"350px"}}>
              <div className="project__item h-100" >
                <div className="image h-100  ">
                  <img src={image} alt="project" className='img-content-fit' loading='lazy'/> 
                </div>
                <div className="project__content">
                  <div className="con">
                    {/* <h4><a href="project-single.html" className="text-white"> {name}</a></h4> */}
                    {/* <span className="text-white">Volunteer</span> */}
                  </div>
                 {/* <a href="project-single.html" class="next-btn"><i class="fa-regular fa-arrow-right"></i></a> */}
                </div>
              </div>
            </div>
          );
        

  return (
    <>
     <Helmet>
      <title>Gallery | Joining Hands </title>
     </Helmet>
    <main>
        {/* <!-- Page banner area start here --> */}
        <section className="banner__inner-page bg-image pt-160 pb-160 bg-image" style={{backgroundImage:"url('/images/banner/gallery.jpg')", position:"relative"}}
          >
               <div 
    className="overlay" 
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(1, 0, 0, 0.5)'
    }}
  ></div>
            <div className="container overlay_content">
                <h2 className="wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">Gallery</h2>
                <div className="breadcrumb-list wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                    <Link to={'/'}>Home</Link><span><i className="fa-regular fa-angles-right mx-2"></i>Gallery</span>
                </div>
            </div>
        </section>
        {/* <!-- Page banner area end here --> */}

        {/* <!-- Project area start here --> */}
        <section className="project-area pt-120 pb-120">
          <div className="container">
           <div className="row g-4">
              {projects.map((project, index) => (
              <ProjectItem key={index} {...project} />
          ))}
          </div>
          </div>
       </section> 
        {/* <!-- Project area end here --> */}
    </main>
    </>
  );
}
export default Project;
