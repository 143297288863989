import React from 'react';
import { Helmet } from 'react-helmet';
import './Blog.css'
import { Link } from 'react-router-dom';
import { Underline } from '../section/Underline';

const BlogItem = ({ image, tag, name, content, isReversed }) => {
  return (
    <>
      <Helmet>
        <title>Stories of hope | Joining Hands</title>
      </Helmet>
      <div className={`blog__item row ${isReversed ? 'reversed' : ''}`}>
        <div className="col-md-12 image_box">
          <img src={image} alt={name} />
        </div>
        <div className="col-md-12 blog__content d-flex align-items-center">
          <div>
            <h4 className="fw-bold" style={{ color: "#f74f22" }}>{name}</h4>
            <p className="fw-semibold">{content}</p>
          </div>
        </div>
      </div>
    </>
  );
};

const Blog = () => {
  const blogItems = [
    {
      image: "/images/project/hope/hope_ashok.jpg",
      tag: "Asst. Manager",
      name: "Ashok",
      content:'"I used to just lurk around here and there and after joining this course I finally started realising the importance of jobs. Now I just don’t feel like leaving my workplace, I enjoy it to the fullest." – Ashok, works with Banaras ( Pan Parlour) as Asst. Manager.'
    },
    {
      image: "/images/project/hope/hope_laxmi.jpg",
      tag: "Scholarship Programme",
      name: "Laxmi",
      content:"'This scholarship program has directed me towards my goals and has given me a new life' – Laxmi Haldar, pursuing a BSE program from Institute of Hotel Management, Panipat, supported by Joining Hands Higher Education Scholarship Programme."
    },
    {
      image: "/images/project/hope/hope_roshni.jpg",
      tag: "Hospitality Skills Development Programme",
      name: "Roshni",
      content:"'After joining the course my knowledge on computers improved. Earlier, I used to hesitate to speak in English in front of people, but Joining Hands trained and helped me to develop my spoken English skills. The classes on financial literacy also proved to be very helpful for the profile that I am currently working in.'"
    },
    {
      image: "/images/project/hope/hope_kavya.jpg",
      tag: "Competent Software ",
      name: "Kavya",
      content: "I am very grateful to Joining Hands for providing me this opportunity to fulfil my dreams. In this scholarship programme you are not only giving financial support, but you are also conducting mentorship programme which provides career guidance sessions and trains us in life skills and soft skills. In the future, if I become a successful person, I will do my bit and will give back to the society - Niveditha, pursuing B. Sc course at BGS Science Academy, Chickballapur, Karnataka, supported by Joining Hands Higher Education Scholarship Programme."
    },
    {
      image: "/images/project/hope/hope_roshni.jpg",
      tag: "Hospitality Skills Development Programme",
      name: "Sukhwinder",
      content:"Before I had no one to guide me or motivate me in my life. Through some friends I g ot to know about Joining Hands and ever since there has been no looking back for me. The trainers and counsellor at the center guided and motivated me in the right path and helped me to overcome my weakness and work on my strength - Sukhwinder trained under the Joining Hands Skill Enhancement Programme and presently working with Pamac Finance Service Private LTD, Noida."
    },
    {
      image: "/images/project/hope/hope_niveditha.jpg",
      tag: "Scholarship Programme",
      name: "Niveditha",
      content:"I am very grateful to Joining Hands for providing me this opportunity to fulfil my dreams. In this scholarship programme you are not only giving financial support, but you are also conducting mentorship programme which provides career guidance sessions and trains us in life skills and soft skills. In the future, if I become a successful person, I will do my bit and will give back to the society - Niveditha, pursuing B. Sc course at BGS Science Academy, Chickballapur, Karnataka, supported by Joining Hands Higher Education Scholarship Programme."
    },
    {
      image: "/images/project/hope/hope_archana.jpg",
      tag: "Software Developer",
      name: "Archana",
      content:"Joining Hands has completely changed my life. We were trained in dealing with job interviews and developing our self-confidence. As a result I landed this job of computer teacher at Khushii NGO where, I am learning so many new things and taking on new responsibilities. I would like to especially thank all the mentors at Joining Hands for supporting me and teaching me the lessons of life.' - Archana, trained under the Joining Hands Skill Enhancement Programme and presently working with Khushii (NGO), Devli, Delhi as Computer Trainer"
    },
    {
      image: "/images/project/hope/hope_roshni.jpg",
      tag: "Hospitality Skills Development Programme",
      name: "Roshni",
      content:"'Initially, I was not very sure about what I wanted to do in life. My batch mate referred me to the Hospitality course in Joining Hands Training Centre. I gradually started taking interest in the classes. Through this course I actually learnt a lot and got a great exposure. And I must say it was the best decision of my life. I am currently working with Nando’s and I love my job! '- Roshni, trained under the Joining Hands Hospitality Skills Development Programme and presently working with Nando’s, Nehru Place, Delhi."
    },
    // Add more blog items here...
  ];

  return (
    <main>
      <section className="border  border-2 border-black banner__inner-page bg-image pt-160 pb-160 bg-image" style={{backgroundImage: "url('images/banner/success.webp')",position: "relative"}}
                  >
                      <div 
          className="overlay" 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(1, 0, 0, 0.5)'
          }}
        ></div>
                
            <div className="container overlay_content">
                <h2 className="wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">Stories of Hope</h2>
                <div className="breadcrumb-list wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                    <a href="index.html">Home</a><span className='text-white'><i className="fa-regular fa-angles-right mx-2"></i>Stories of Hope</span>
                </div>
            </div>
        </section>

      <section className="blog-area pb-120">
        <div className="container my-5">
          <div className="row justify-content-center pt-50 pb-50">
            <h2 className="text-center fw-bold">Our Successful Stories</h2>
            <Underline />
          </div>
          <div className="row g-4">
            {blogItems.map((item, index) => (
              <div key={index} className="col-xl-4 blogitem_container wow fadeInUp p-4">
                <BlogItem {...item} isReversed={index % 2 !== 0} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Blog;