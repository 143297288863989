import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const ScholarshipProgramme = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const testimonials = [
    {
      
      image: "/images/testimonial/continuous5.jpg",
    },
    {
     
      image: "/images/testimonial/employee.jpg",
    },
    {
      image: "/images/testimonial/extended.jpg",
    },
    {
     
      image: "/images/testimonial/identification.jpg",
    },
    {
     
      image: "/images/testimonial/individual_education.jpg",
    },
   
  ];

  return (
    <section className="testimonial-area pt-100 pb-100  ">
      <div className="container px-0">
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-4 mb-60">
          <div className="section-header">
            <h5 className="wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
            <i class="fa-regular fa-angles-left pe-1"></i>Joining hands Higher education Scholarship programme <i
                                class="fa-regular fa-angles-right ps-1"></i>
            </h5>
            <h2 className="wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">

This initiative will be carried in five phases to have more effectiveness.
   </h2>
          </div>
          <div className="arry-btn wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
         
          </div>
        </div>
      </div>
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={1}
        slidesPerView={1.1}
        centeredSlides={true}
        loop={true}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          640: { slidesPerView: 1.6 },
          768: { slidesPerView: 1.2 },
          1024: { slidesPerView: 2.5 },
          // 1280: { slidesPerView: 3.5 }
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="testimonial__item ">
                <div className='image_box '>
                    <img style={{width: "100%", height:"100%"}} className='' src={testimonial.image} alt={testimonial.name}/>
                </div>
            
              
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default ScholarshipProgramme;