import React, { useEffect, useRef, useState } from 'react';

const CounterSection = () => {
  const [counts, setCounts] = useState({
    happyYouth: 0,
    volunteers: 0,
    products: 0,
    donors: 0
  });

  const sectionRef = useRef(null);
  const animationDuration = 2000; // 2 seconds for the entire animation
  const hasAnimatedRef = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimatedRef.current) {
          startCounting();
          hasAnimatedRef.current = true;
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const startCounting = () => {
    const targets = {
      happyYouth: 3500,
      volunteers: 18000,
      products: 3150,
      donors: 8700
    };

    const startTime = Date.now();

    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / animationDuration, 1);

      setCounts(prevCounts => {
        const newCounts = {};
        Object.keys(targets).forEach(key => {
          newCounts[key] = Math.round(progress * targets[key]);
        });
        return newCounts;
      });

      if (progress === 1) {
        clearInterval(interval);
      }
    }, 16); // Update roughly every frame (60 FPS)
  };

  return (
    <section className="counter-area pt-100 pb-100" ref={sectionRef}>
      <div className="container">
        <div className="counter__wrp">
          <CounterItem
            icon="/images/icon/counter1.png"
            count={counts.happyYouth}
            text="Total Happy Youth"
          />
          <CounterItem
            icon="/images/icon/counter2.png"
            count={counts.volunteers}
            text="Total Our Volunteer"
          />
          <CounterItem
            icon="/images/icon/counter3.png"
            count={counts.products}
            text="Total Youth Skilled"
          />
          {/* <CounterItem
            icon="/images/icon/counter4.png"
            count={counts.donors}
            text="Worldwide Donor"
          /> */}
        </div>
      </div>
    </section>
  );
};

const CounterItem = ({ icon, count, text }) => (
  <div className="counter__item wow fadeInUp " data-wow-delay="00ms" data-wow-duration="1500ms">
    <div className="icon">
      <img src={icon} alt="icon" />
    </div>
    <div className="content text-center">
      <h3><span className="count">{count}</span>+</h3>
      <span>{text}</span>
    </div>
  </div>
);

export default CounterSection;